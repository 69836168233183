// src/components/Button.js
import styled from 'styled-components';

const Button = styled.button`
  background: #00ffff;
  color: #0d0d0d;
  border: none;
  padding: 15px 40px;
  font-size: 1.2rem;
  cursor: pointer;
  font-family: 'Rajdhani', sans-serif;
  border-radius: 5px;
  transition: background 0.3s ease;
  &:hover {
    background: #009e9e;
  }
`;

export default Button;
