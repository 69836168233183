// src/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    background-color: #0d0d0d;
    color: #fff;
    font-family: 'Rajdhani', sans-serif;
  }
  a {
    color: #00ffff;
    text-decoration: none;
  }
`;

export default GlobalStyles;
