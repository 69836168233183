// src/pages/HomePage.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Button from '../components/Button';

const HomeContainer = styled.div`
  background: url('/images/home-background.jpg') no-repeat center center/cover;
  min-height: calc(100vh - 160px); /* Adjust for Navbar and Footer */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 60px 20px;
  @media (max-width: 768px) {
    padding: 100px 20px;
  }
`;

const HomeContent = styled.div`
  color: #fff;
  max-width: 800px;
  padding: 20px;
`;

const Title = styled(motion.h1)`
  font-size: 4rem;
  font-family: 'Press Start 2P', cursive;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  font-family: 'Press Start 2P', cursive;
  margin-top: 60px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const GameLogo = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 30px;
`;

const DownloadText = styled.p`
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const StoreLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StoreLink = styled.a`
  color: #00ffff;
  font-size: 1.2rem;
  margin: 0 15px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  span {
    text-decoration: underline;
  }
`;

function HomePage() {
  return (
    <HomeContainer>
      <HomeContent>
        <Title
          initial={{ y: -250 }}
          animate={{ y: 0 }}
          transition={{ type: 'spring', stiffness: 120 }}
        >
          Welcome to Zobbo Games
        </Title>
        <Subtitle
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          Your ultimate gaming experience starts here.
        </Subtitle>
        <Button
          as={motion.button}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Get Started
        </Button>

        {/* New Section for Our Game */}
        <SectionTitle>Our Games</SectionTitle>
        <DownloadText>Age of Traffic: Car Jam Escape</DownloadText>
        <GameLogo src="/images/ios-game-logo.png" alt="Our Game" />
        <DownloadText>Download on</DownloadText>
        <StoreLinks>
          <StoreLink
            href="https://play.google.com/store/apps/details?id=com.zobbogames.ageoftrafficgame3d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Play Store</span>
          </StoreLink>
          <StoreLink
            href="https://apps.apple.com/us/app/age-of-traffic-car-jam-escape/id6581480442"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>App Store</span>
          </StoreLink>
        </StoreLinks>
      </HomeContent>
    </HomeContainer>
  );
}

export default HomePage;
