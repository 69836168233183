// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background: #1a1a1a;
  color: #fff;
  text-align: center;
  padding: 20px;
  font-size: 1rem;
`;

const Address = styled.p`
  margin: 10px 0;
`;

const SocialIcons = styled.div`
  margin-top: 20px;
  & > a {
    margin: 0 10px;
    color: #fff;
    font-size: 1.5rem;
    &:hover {
      color: #00ffff;
    }
  }
`;

function Footer() {
  return (
    <FooterContainer>
      &copy; {new Date().getFullYear()} Zobbo Games. All rights reserved.
      <Address>
      1111B South Governors Avenue, STE 7664<br />
      Dover, DE 19904
      </Address>
      <SocialIcons>
        <a
          href="https://www.facebook.com/profile.php?id=61564101238382"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61564101238382"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61564101238382"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
      </SocialIcons>
    </FooterContainer>
  );
}

export default Footer;
