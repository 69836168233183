// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaGamepad } from 'react-icons/fa';

const Nav = styled.nav`
  background: #1a1a1a;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 50px;
`;

const Logo = styled(Link)`
  color: #00ffff;
  font-size: 2rem;
  font-family: 'Press Start 2P', cursive;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const LogoIcon = styled(FaGamepad)`
  margin-right: 10px;
`;

const NavMenu = styled.div`
  display: flex;
  margin-left: auto;
`;

const NavItem = styled(Link)`
  color: #fff;
  margin-left: 30px;
  font-size: 1.2rem;
  text-decoration: none;
  position: relative;
  &:hover {
    color: #00ffff;
  }
  &:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 2px;
    background: #00ffff;
    left: 0;
    bottom: -5px;
    transition: width 0.3s;
  }
  &:hover:after {
    width: 100%;
  }
`;

function Navbar() {
  return (
    <Nav>
      <Logo to="/">
        <LogoIcon />
        Zobbo Games
      </Logo>
      <NavMenu>
        <NavItem to="/">Home</NavItem>
        <NavItem to="/help">Help</NavItem>
      </NavMenu>
    </Nav>
  );
}

export default Navbar;
