// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyles from './GlobalStyles';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import HelpPage from './pages/HelpPage';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/help" element={<HelpPage />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
