// src/pages/HelpPage.js
import React from 'react';
import styled from 'styled-components';
import { FaEnvelope } from 'react-icons/fa';

const HelpContainer = styled.div`
  padding: 100px 20px;
  text-align: center;
  color: #fff;
  background-color: #121212;
  min-height: calc(100vh - 160px);
`;

const Title = styled.h1`
  font-size: 3rem;
  font-family: 'Press Start 2P', cursive;
  margin-bottom: 30px;
`;

const Text = styled.p`
  font-size: 1.2rem;
  margin-bottom: 50px;
`;

const ContactLink = styled.a`
  color: #00ffff;
  font-size: 1.5rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  &:hover {
    color: #fff;
  }
`;

function HelpPage() {
  return (
    <HelpContainer>
      <Title>Need Assistance?</Title>
      <Text>We're here to help. Reach out to us anytime.</Text>
      <ContactLink href="mailto:admin@zobbogames.com">
        <FaEnvelope style={{ marginRight: '10px' }} />
        admin@zobbogames.com
      </ContactLink>
    </HelpContainer>
  );
}

export default HelpPage;
